import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  firstname: null,
  middlename: null,
  lastname: null,
  email: null,
  image: null,
  phone: null,
  address: null,
  isDeveloper: false,
  currentTeam: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    setPersonalDetails: (
      state,
      {
        payload: {
          id,
          firstname,
          middlename,
          lastname,
          email,
          image,
          phone,
          address,
          developer,
        },
      }
    ) => {
      state.id = id;
      state.firstname = firstname;
      state.middlename = middlename;
      state.lastname = lastname;
      state.email = email;
      state.image = image;
      state.phone = phone;
      state.address = address;
      state.isDeveloper = developer;
    },

    setTeam: (state, { payload: { team } }) => {
      state.currentTeam = team;
    },
    resetPersonalDetails: (state) => {
      state.id = null;
      state.firstname = null;
      state.middlename = null;
      state.lastname = null;
      state.email = null;
      state.phone = null;
      state.image = null;
      state.address = null;
      state.isDeveloper = false;
      state.currentTeam = null;
    },
    resetCurrentTeam: (state) => {
      state.currentTeam = null;
    },
  },
});

export const {
  setPersonalDetails,
  setTeam,
  resetPersonalDetails,
  resetCurrentTeam,
} = userSlice.actions;

export default userSlice.reducer;
