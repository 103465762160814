import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      "Objective Regular",
      "Objective Bold",
      "Objective Thin",
      "Back Groove",
      "Helvetica Neue",
      "Segoe UI",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    // Other typography options...
  },
  palette: {
    primary: {
      main: "#354f52",
      // main: "#06412E",
    },
    secondary: {
      main: "#84a98c",
      hover: "#d4e1d7",
      // alt: "#78AF9E",
    },
    error: {
      main: "#D43B31",
      hover: "#d0442f",
    },
    option: {
      hover: "#64dbea",
      main: "#1ecbe1",
    },
    white: {
      main: "#FFFFFF",
    },
    black: {
      main: "#F3FFFE",
    },
    background: {
      default: "#F5F5F5",
    },
  },
});

export default theme;
