import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBarLock: false,
};

export const systemSlice = createSlice({
  name: "system",
  initialState,

  reducers: {
    setSideBarLock: (state, { payload: { lock } }) => {
      state.sideBarLock = lock;
    },
  },
});

export const { setSideBarLock } = systemSlice.actions;

export default systemSlice.reducer;
