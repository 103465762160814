import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.access_token;
      if (token) {
        //console.log('token', token);
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User", "UserTeams", "Teams"],

  endpoints: (builder) => ({
    getUser: builder.query({
      query() {
        return {
          url: `/me`,
          method: "GET",
        };
      },
      providesTags: ["User"],
      transformResponse: (response) => response,
    }),

    editUserLogo: builder.mutation({
      query(body) {
        const { data, email } = body;

        return {
          url: `/users/${email}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["User"],
      transformResponse: (response) => response,
    }),

    editUser: builder.mutation({
      query(body) {
        const { data, email } = body;

        return {
          url: `/users/${email}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["User"],
      transformResponse: (response) => response,
    }),

    changePassword: builder.mutation({
      query(body) {
        const { data, email } = body;
        return {
          url: `/users/${email}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => response,
    }),

    getUserTeams: builder.query({
      query(param) {
        return {
          url: `/teams?full_data=1&include=userRoles${param}`,
          method: "GET",
        };
      },
      providesTags: ["UserTeams"],
      transformResponse: (response) => response,
    }),

    loginUser: builder.mutation({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
    }),

    logoutUser: builder.mutation({
      query() {
        return {
          url: "/logout",
          method: "POST",
        };
      },
      invalidatesTags: ["User"],
    }),

    isLoggedIn: builder.mutation({
      query(body) {
        return {
          url: "/auth/check",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useIsLoggedInMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useEditUserMutation,
  useEditUserLogoMutation,
  useGetUserTeamsQuery,
  useChangePasswordMutation,
} = userApi;
