import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "./storage";

// RTK Query
import { userApi } from "./services/userAPi";
import { formApi } from "./services/formApi";
import { passApi } from "./services/passApi";
import { teamApi } from "./services/teamsApi";
import { downloadApi } from "./services/downloadApi";

//Redux
import authReducer from "./persists/authSlice";
import userReducer from "./persists/userSlice";
import registerReducer from "./persists/registerSlice";
import systemReducer from "./persists/systemSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  register: registerReducer,
  system: systemReducer,
  [userApi.reducerPath]: userApi.reducer,
  [passApi.reducerPath]: passApi.reducer,
  [formApi.reducerPath]: formApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [downloadApi.reducerPath]: downloadApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    userApi.reducerPath,
    passApi.reducerPath,
    formApi.reducerPath,
    teamApi.reducerPath,
    downloadApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      userApi.middleware,
      passApi.middleware,
      formApi.middleware,
      teamApi.middleware,
      downloadApi.middleware
    ),
});

setupListeners(store.dispatch);
