import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const downloadApi = createApi({
  reducerPath: "downloadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.access_token;
      if (token) {
        //console.log('token', token);
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set("Access-Control-Allow-Origin", "http://localhost:8000");
      return headers;
    },
  }),
  tagTypes: [],

  endpoints: (builder) => ({
    downloadFormSubmissions: builder.query({
      query(data) {
        const { slug, param, team, user, keyword } = data;
        return {
          url: `/submissions/?${keyword}&filter[form]=${slug}${user}${team}${param}&format=xlsx`,
          method: "GET",
          responseType: "blob",
        };
      },
    }),
  }),
});

export const { useDownloadFormSubmissionsQuery } = downloadApi;
