import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_token: null,
  refresher_token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    setCredentials: (state, { payload: { access_token, refresher_token } }) => {
      state.access_token = access_token;
      state.refresher_token = refresher_token;
    },
    resetCredentials: (state) => {
      state.access_token = null;
      state.refresher_token = null;
    },
  },
});

export const { setCredentials, resetCredentials } = authSlice.actions;

export default authSlice.reducer;
