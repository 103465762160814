exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[id]-js": () => import("./../../../src/pages/[id].js" /* webpackChunkName: "component---src-pages-[id]-js" */),
  "component---src-pages-colleagues-[id]-js": () => import("./../../../src/pages/colleagues/[id].js" /* webpackChunkName: "component---src-pages-colleagues-[id]-js" */),
  "component---src-pages-forms-[id]-js": () => import("./../../../src/pages/forms/[id].js" /* webpackChunkName: "component---src-pages-forms-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-[id]-js": () => import("./../../../src/pages/profile/[id].js" /* webpackChunkName: "component---src-pages-profile-[id]-js" */),
  "component---src-pages-teams-[id]-js": () => import("./../../../src/pages/teams/[id].js" /* webpackChunkName: "component---src-pages-teams-[id]-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

