import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const formApi = createApi({
  reducerPath: "formApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.access_token;
      if (token) {
        //console.log('token', token);
        headers.set("authorization", `Bearer ${token}`);
      }
      headers.set("Accept", `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    "Form",
    "Forms",
    "FieldTypes",
    "Submissions",
    "Submission",
    "Notes",
    "Activities",
    "FormTeams",
  ],

  endpoints: (builder) => ({
    addForms: builder.mutation({
      query(body) {
        return {
          url: `/forms`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Forms"],
      transformResponse: (response) => response,
    }),

    getForms: builder.query({
      query(data) {
        const { team, title, visibility } = data;
        return {
          url: `/forms?full_data=1&filter[team]=${team}${title}${visibility}&&include=sectionsCount,fieldsCount,submissionsCount,teams`,
          method: "GET",
        };
      },
      providesTags: ["Forms"],
      transformResponse: (response) => response,
    }),

    getFormTeams: builder.query({
      query(data) {
        const { slug, team } = data;
        return {
          url: `/teams/?full_data=1&filter[form]=${slug}${team}`,
          method: "GET",
        };
      },
      providesTags: ["FormTeams"],
      transformResponse: (response) => response,
    }),

    searchForms: builder.query({
      query(title) {
        return {
          url: `/forms?full_data=1&filter[title]=${title}`,
          method: "GET",
        };
      },
      providesTags: ["Forms"],
      transformResponse: (response) => response,
    }),

    showForm: builder.query({
      query(slug) {
        return {
          url: `/forms/${slug}`,
          method: "GET",
        };
      },
      providesTags: ["Form"],
      transformResponse: (response) => response,
    }),
    showFormConstants: builder.query({
      query(slug) {
        return {
          url: `/forms/${slug}/constants`,
          method: "GET",
        };
      },
      providesTags: ["Form"],
      transformResponse: (response) => response,
    }),

    editForm: builder.mutation({
      query(body) {
        const { data, slug } = body;
        return {
          url: `/forms/${slug}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Form", "FormTeams"],
      transformResponse: (response) => response,
    }),

    submitForm: builder.mutation({
      query(data) {
        const { slug, body, team } = data;
        return {
          url: `/teams/${team}/forms/${slug}/submissions`,
          method: "POST",
          body: body,
          formData: true,
        };
      },
      // providesTags: ["Form"],
      transformResponse: (response) => response,
    }),

    getFieldTypes: builder.query({
      query() {
        return {
          url: `/field-types`,
          method: "GET",
        };
      },
      providesTags: ["FieldTypes"],
      transformResponse: (response) => response,
    }),

    getFormSubmissions: builder.query({
      query(data) {
        const { slug, param, team, user, keyword, page } = data;
        return {
          url: `/submissions/?${keyword}&filter[form]=${slug}${user}${team}${param}&include=team,user,notesCount&per_page=10&page=${page}`,
          method: "GET",
        };
      },
      providesTags: ["Submissions"],
      transformResponse: (response) => response,
    }),

    getFormSubmission: builder.query({
      query(data) {
        const { uuid } = data;
        return {
          url: `/submissions/${uuid}/?include=responses,responsesCount&include=notes,notesCount`,
          method: "GET",
        };
      },
      providesTags: ["Submission"],
      transformResponse: (response) => response,
    }),

    getFormSubmissionActivities: builder.query({
      query(data) {
        const { uuid, page } = data;
        return {
          url: `/submissions/${uuid}/activities?per_page=15&page=${page}`,
          method: "GET",
        };
      },
      providesTags: ["Activities"],
      transformResponse: (response) => response,
    }),

    getFormSubmissionNotes: builder.query({
      query(data) {
        const { uuid } = data;
        return {
          url: `/submissions/${uuid}/notes?include=notesCount`,
          method: "GET",
        };
      },
      providesTags: ["Notes"],
      transformResponse: (response) => response,
    }),

    addFormSubmissionNotes: builder.mutation({
      query(data) {
        const { uuid, body } = data;
        return {
          url: `/submissions/${uuid}/notes`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Notes"],
      transformResponse: (response) => response,
    }),

    updateFormSubmission: builder.mutation({
      query(input) {
        const { uuid, data } = input;
        return {
          url: `/submissions/${uuid}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Submissions"],
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useAddFormsMutation,
  useEditFormMutation,
  useGetFormsQuery,
  useGetFormTeamsQuery,
  useSearchFormsQuery,
  useShowFormQuery,
  useShowFormConstantsQuery,
  useGetFieldTypesQuery,
  useSubmitFormMutation,
  useGetFormSubmissionsQuery,
  useGetFormSubmissionQuery,
  useUpdateFormSubmissionMutation,
  useAddFormSubmissionNotesMutation,
  useGetFormSubmissionNotesQuery,
  useGetFormSubmissionActivitiesQuery,
} = formApi;
