import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const teamApi = createApi({
  reducerPath: "teamApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.access_token;
      if (token) {
        //console.log('token', token);
        headers.set("authorization", `Bearer ${token}`);
      }
      // headers.set("Content-type", `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    "teamDetails",
    "teamUsers",
    "teamUser",
    "teamForms",
    "searchUser",
    "Roles",
    "Teams",
    "Team",
    "UserByEmail",
    "UserByMobile",
  ],

  endpoints: (builder) => ({
    addTeam: builder.mutation({
      query(data) {
        return {
          url: `/teams`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Teams"],
      transformResponse: (response) => response,
    }),

    editTeamLogo: builder.mutation({
      query(body) {
        const { data, slug } = body;

        return {
          url: `/teams/${slug}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["Teams", "teamDetails", "teamUsers", "teamForms"],
      transformResponse: (response) => response,
    }),

    editTeam: builder.mutation({
      query(body) {
        const { data, slug } = body;

        return {
          url: `/teams/${slug}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Teams", "teamDetails", "teamUsers", "teamForms"],
      transformResponse: (response) => response,
    }),

    disableEnableTeam: builder.mutation({
      query(body) {
        const { data, slug } = body;
        return {
          url: `/teams/${slug}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Teams", "teamDetails", "teamUsers", "teamForms"],
      transformResponse: (response) => response,
    }),

    getTeamDetails: builder.query({
      query(name) {
        return {
          url: `/teams/${name}`,
          method: "GET",
        };
      },
      providesTags: ["teamDetails"],
      transformResponse: (response) => response,
    }),

    getTeams: builder.query({
      query(input) {
        const { search, visibility } = input;
        return {
          url: `/teams?full_data=1&filter[email]=${search}${visibility}`,
          method: "GET",
        };
      },
      providesTags: ["Teams"],
      transformResponse: (response) => response,
    }),

    getAllTeams: builder.query({
      query(email) {
        return {
          url: `/teams?full_data=1`,
          method: "GET",
        };
      },
      providesTags: ["Teams"],
      transformResponse: (response) => response,
    }),

    addTeamUser: builder.mutation({
      query(data) {
        return {
          url: `/users`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Teams", "teamUsers"],
      transformResponse: (response) => response,
    }),

    getTeamUser: builder.query({
      query(email) {
        return {
          url: `/users?filter[email]=${email}&&include=userRoles&&filter[withDisabled]=1`,
          method: "GET",
        };
      },
      providesTags: ["teamUser"],
      transformResponse: (response) => response,
    }),

    disableTeamUser: builder.mutation({
      query(input) {
        const { data, email } = input;
        return {
          url: `/users/${email}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["teamUsers"],
      transformResponse: (response) => response,
    }),

    getTeamUsers: builder.query({
      query(data) {
        const { team, search, visibility, page } = data;
        return {
          url: `/users?per_page=10&page=${page}&filter[team]=${team}&&include=userRoles&&filter[email]=${search}${visibility}`,
          method: "GET",
        };
      },
      providesTags: ["teamUsers"],
      transformResponse: (response) => response,
    }),

    getTeamForms: builder.query({
      query(data) {
        const { team, title } = data;
        return {
          url: `/forms?full_data=1&filter[team]=${team}${title}&include=sectionsCount,fieldsCount,submissionsCount,`,
          method: "GET",
        };
      },
      providesTags: ["teamForms"],
      transformResponse: (response) => response,
    }),

    getUserRole: builder.query({
      query(param) {
        return {
          url: `/roles/?${param}`,
          method: "GET",
        };
      },
      providesTags: ["Roles"],
      transformResponse: (response) => response,
    }),

    getUserByEmail: builder.query({
      query(email) {
        return {
          url: `/users?filter[email]=${email}`,
          method: "GET",
        };
      },
      providesTags: ["UserByEmail"],
      transformResponse: (response) => response,
    }),

    getUserByMobile: builder.query({
      query(mobile) {
        return {
          url: `/users?filter[mobile]=${mobile}`,
          method: "GET",
        };
      },
      providesTags: ["UserByMobile"],
      transformResponse: (response) => response,
    }),

    getUsersSearch: builder.query({
      query(email) {
        return {
          url: `/users?filter[email]=${email}&&include=teams`,
          method: "GET",
        };
      },
      providesTags: ["searchUser"],
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useAddTeamMutation,
  useEditTeamMutation,
  useEditTeamLogoMutation,
  useDisableEnableTeamMutation,

  // useGetTeamQuery,
  useGetTeamsQuery,
  useGetAllTeamsQuery,
  useLazyGetTeamsQuery,
  useGetTeamDetailsQuery,
  useGetTeamUsersQuery,
  useDisableTeamUserMutation,
  useGetTeamFormsQuery,
  useGetTeamUserQuery,

  useAddTeamUserMutation,
  useGetUsersSearchQuery,
  useGetUserRoleQuery,
  useGetUserByEmailQuery,
  useGetUserByMobileQuery,
  useLazyGetUserByMobileQuery,
  useLazyGetUserByEmailQuery,
} = teamApi;
